.impress-margin {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-top {
  margin-top: 80px;
}

.margin-rund {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.centering {
  max-width: 700px;
  margin: 0 auto;
}

.textcenter {
  text-align: center;
}

.txtleft {
  text-align: left;
}

.txtright {
  text-align: right;
}

body {
  background-color: E40017;
}

.lieferbit {
  font-size: 3.5em;
  font-weight: 800;
}

.titel {
  font-size: 2.9em;
}

.bullets {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.gross {
  font-size: 1.8em;
}

.bisschen {
  font-size: 1.4em;
}

.texteins {
  font-size: 0.7em;
}

.unterstrichen {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.start-img {
  max-height: 300px;
}

.fweight {
  font-weight: 500;
}

.fweightgross {
  font-weight: 800;
}

.textweiss {
  color: white;
}

.italic {
  font-style: italic;
}

.margin-agb {
  margin-left: 5px;
  margin-right: 5px;
}

button {
  margin-top: 10px;
  padding: 8px 12px;
  background: #142f43;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  border-radius: 7px !important;
  color: #ffab4c;
}

.textrot {
  color: #a20a0a;
}

.hkachel-aussen {
  /* height: 5vh; */
  background: #a20a0a;
  margin: 5px;
}

.chipi {
  /* height: 5vh; */
  background: blueviolet;
  margin: 5px;
}

.txtrosa {
  color: #ff5f7e;
}

.txtdunkel {
  color: #142f43;
}

.txtgelb {
  color: #ffab4c;
}

.txtlila {
  color: #b000b9;
}

.footer {
  bottom: 0;
  color: #ff5f7e;
}

.abstandu {
  height: 280px;
}

.abstanduklein {
  height: 40px;
}

.abstandmini {
  margin-bottom: 5px;
}

.abstrechts {
  margin-right: 5px;
}

.hobbydiv {
  border-radius: 15px;
  background: #142f43;
  padding: 5px;
  width: 40%;
  margin-bottom: 10px;
}

.chatanders {
  text-align: right;
  border-radius: 5px;
  background: #142f43;
  padding: 5px;
  width: 60%;
  color: #ffab4c;
  margin-bottom: 5px;
  margin-right: 20px;
}

.chatich {
  text-align: left;
  border-radius: 5px;
  background: #ffab4c;
  color: #b000b9;
  padding: 5px;
  width: 50%;
  margin-bottom: 5px;
  margin-right: 20px;
}

.abstandlinks {
  border-radius: 5px;
  background: #ff5f7e;
  padding: 5px;
  margin-left: 10px;
  color: #142f43;
}

.bodenfix {
  display: flex;
  flex-direction: row;
}
